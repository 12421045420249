@import './theme/reset';
@import './theme/fonts';

body {
  margin: 0;
  padding: 0;
  background: #ffffff;
  user-select: none;
  // transition: background 0.1s ease-in;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

div {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-focus-ring-color: rgba(0, 0, 0, 0);
}
