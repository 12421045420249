html,
body,
ul,
ol {
  margin: 0;
  padding: 0;
}

button:focus {
  outline: none;
}
