@import '../../theme/theme';

.Preloader {
  background: $primary-color;
  width: 100vw;
  height: 100vh;
  display: block;

  .animatedLogo {
    background-image: url('../../assets/images/weoplay_logo.svg');
    background-size: 80px 42px;
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    width: 80px;
    height: 42px;
    top: -webkit-calc(50% - 21px);
    top: -moz-calc(50% - 21px);
    top: calc(50% - 21px);
    left: -webkit-calc(50% - 40px);
    left: -moz-calc(50% - 40px);
    left: calc(50% - 40px);
    -webkit-animation-name: animated-logo;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    animation-name: animated_logo;
    animation-duration: 0.7s;
    animation-iteration-count: infinite;
  }

  @-webkit-keyframes animated_logo {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes animated_logo {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }
}
